'use client'

import { Header, Footer } from '@/components'

import Page404 from '@/components/Page404/Page404'

export default function Error() {
  return (
    <>
      <Header hiddenMenuDesktop={true} />
      <Page404 />
      <Footer />
    </>
  )
}
